
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}

.modal {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(81, 255, 0, 0.14);
  transition: all .4s;
}

.modal.open {
    visibility: visible;
    opacity: 1;
    transition: all .4s;
}

.modal__content {
  border-radius: 4px;
  position: relative;
  width: 500px;
  max-width: 90%;
  background: #fff;
  padding: 2em;
}
.modal__title{
  font-size: 1.3rem;
}

.modal__close {
  position: absolute;
  top: 0;
  right: 10px;
  color: #585858;
  text-decoration: none;
  font-size: 2rem;
  cursor: pointer;
}

.myCursor{
    cursor: pointer;
}

button,
button:focus,
input,
input:focus,
*:focus {
	outline: none !important;
	box-shadow: none !important;
}
input::-moz-focus-inner,
button::-moz-focus-inner {
	border: 0 !important;
	box-shadow: none !important;
}
::-webkit-scrollbar {
	width: 8px;
	height: 10px;
}
.noScroll::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #ebf1ff;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb {
	background: #fbb100;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
	background: #65D133;
}

.textTrunc {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}
.textTrunc2 {
	-webkit-line-clamp: 2;
}
.textTrunc3 {
	-webkit-line-clamp: 3;
}
.textTrunc4 {
	-webkit-line-clamp: 4;
}
.textTrunc5 {
	-webkit-line-clamp: 5;
}
.textTrunc10 {
	-webkit-line-clamp: 10;
}
.style-module_simpleStarRating__nWUxf svg {
	display: inline !important;
}